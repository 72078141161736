
import axios from "@/plugins/axios.js"
import { useToast } from 'vue-toastification'
const toast = useToast()

const actions = {
  alertSuccess({}, message) {
      toast.success(message)
  },
  alertError({}, message) {
      toast.error(message)
  },
  changeLoaderValue({ commit }, val = false) {
    commit("TOGGLE_LOADER", val)
  },
  logoutUser({ commit, rootState }, action) {
    commit("LOGOUT_USER")
    return new Promise((resolve, reject) => {
      axios.post(`${rootState.domain}/api/logout`)
      .then((response) => {
          resolve(response)
      }).catch((error) => { reject(error) })
    })
  },
  universalFetch({ commit, rootState }, action) {
    return new Promise((resolve, reject) => {
      axios.get(action)
      .then((response) => {
          resolve(response)
      }).catch((error) => { reject(error) })
    })
  },

  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////
  fetchActiveUser({ commit, rootState }, action) {
    return new Promise((resolve, reject) => {
      axios.get("/account/show")
      .then((response) => {
          if(response.data.success){
            commit('UPDATE_USER_INFO', response.data.data)
          }
          resolve(response)
      }).catch((error) => { reject(error) })
    })
  },
  fetchCountries({ commit, rootState }, action) {
    return new Promise((resolve, reject) => {
      axios.get(`${rootState.domain}/api/countries`)
      .then((response) => {
        if(response.data.success){
            commit('SET_COUNTRIES', response.data.data)
        }
        resolve(response)
      }).catch((error) => { reject(error) })
    })
  },
  fetchPlans({ commit, rootState }) {
    if(rootState.plans.length) return ;
    return new Promise((resolve, reject) => {
      axios.get(`${rootState.domain}/api/plans`)
      .then((response) => {
        if(response.data.success){
          commit('SET_PLANS', response.data.data)
        }
        resolve(response)
      }).catch((error) => { reject(error) })
    })
  },
  fetchSubscriptions({ commit, rootState}) {
    if(rootState.subscriptions.length) return
    return new Promise((resolve, reject) => {
      axios.get('/subscriptions/all')
        .then((response) => {
          if(response.data.success){
            commit('SET_SUBSCRIPTIONS', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchCategories({ commit, rootState }) {
    if(rootState.categories.length) return
    return new Promise((resolve, reject) => {
      axios.get(`${rootState.domain}/api/categories`)
        .then((response) => {
          if(response.data.success){
            commit('SET_DEVICE_TYPES', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchStoreTypes({ commit, rootState }) {
    if(rootState.storeTypes.length) return
    return new Promise((resolve, reject) => {
      axios.get(`${rootState.domain}/api/store-types`)
        .then((response) => {
          if(response.data.success){
            commit('SET_STORE_TYPES', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  getClientCountry({commit}, val = false){
    fetch('https://api.ipregistry.co/?key=tryout')
      .then(function (response) {
          return response.json();
      })
      .then(function (payload) {
        commit('SET_COUNTRY', payload.location.country);
      });
  },
}

export default actions
