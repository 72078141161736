export default [
    {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
        path: '/main',
        redirect: '/',
        component: () => import('@/layouts/Main.vue'),
        children: [
            {
              path: '/',
              name: 'dashboard',
              component: () => import('@views/Dashboard.vue'),
              meta: {
                title: 'Dashboard',
                authRequired: true,
              }
            },
            {
              path: '/appointments',
              name: 'appointments',
              component: () => import('@views/appointments/AppointmentList.vue'),
              meta: {
                title: 'Appointments',
                authRequired: true,
              },
            },
            {
              path: '/appointments/:appointId(\\d+)',
              name: 'view-appointment',
              component: () => import('@views/appointments/AppointmentShow.vue'),
              meta: {
                title: "View appointment",
                authRequired: true,
              },
            },
            {
              path: '/account',
              name: 'account',
              component: () => import('@views/account/UserEdit.vue'),
              meta: {
                title: 'Profile',
                authRequired: true,
              },
          },
          {
            path: '/newsletter',
            name: 'newsletter',
            component: () => import('@views/settings/Newsletter.vue'),
            meta: {
              title: 'Newsletter',
              authRequired: true,
            },
          },
          {
            path: '/notifications',
            name: 'notifications',
            component: () => import('@views/notification/NotificationList.vue'),
            meta: {
              title: "Notifications",
              authRequired: true,
            }
          },
        ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
        path: '/full',
        component: () => import('@/layouts/Full.vue'),
        children: [
          {
              path: '/login',
              name: 'login',
              component: () => import('@/views/pages/Login.vue'),
              meta: {
                guestRequired: true,
                title: "Login"
              }
          },
          {
              path: '/error-404',
              name: 'error-404',
              component: () => import('@/views/pages/Error404.vue'),
              meta: {
                title: "404 - Page not Found"
              }
          },
          {
              path: '/error-500',
              name: 'error-500',
              component: () => import('@/views/pages/Error500.vue'),
              meta: {
                title: "500 - Server error"
              }
          },
        ]
    },
    // Redirect to 404 page, if no match found
    {
        path: '/:any(.*)',
        redirect: '/error-404'
    },

]